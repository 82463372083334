<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>教研中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/teacherCourse' }">课程</el-breadcrumb-item>
                <el-breadcrumb-item>添加资料</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
      <div class="content-list-page page-content" style="margin-top: 20px">
        <div class="list-head-box">
          <div class="list-head" data-v-69874ad0="">
            <p class="list-head-title" data-v-69874ad0="">提示</p>
            <ul data-v-69874ad0="">
              <li data-v-69874ad0="">文件必须是：章节名称_小节名称 命名的文件</li>
              <li data-v-69874ad0="">例：第一章_第一节 我的学校资料.pdf</li>
            </ul>
          </div>
        </div>
      </div>
        <div class="detail-info">

            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="mini">

                <!-- <el-form-item label="名称：" label-position="left" prop="name" class="input">
                    <el-input style="width: 200px"  v-model="form.name"></el-input>
                </el-form-item> -->

                <el-form-item label="选择文件：" label-position="left" prop="path">
                    <media-upload :uploadPath="uploadPath" @success="uploadSuccess">
                        <span slot="prompt">文件大小小于500M</span>
                    </media-upload>
                </el-form-item>


                <!-- <el-form-item label="所属章节：" label-position="left" class="input"  prop="teacherCourseChapterId">
                    <el-select v-model="form.teacherCourseChapterId" clearable placeholder="请选择">
                        <el-option
                                v-for="item in chapters"
                                :key="item.id"
                                :label="item.name"
                                :disabled="item.status === 0"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->

                <!--                <el-form-item label="数量：" label-position="left" class="input" prop="workload">-->
                <!--                    <el-input style="width: 200px"  v-model="form.workload">-->
                <!--                        <template slot="append">个</template></el-input>-->
                <!--                </el-form-item>-->

                <el-form-item>
                    <el-button :loading="loading" size="small" type="primary" @click="onSubmit('form')">保存</el-button>
                    <router-link :to="'/teacherCourse/detail/' + this.form.teacherCourseId + '/manage'"
                        class="router-link"><el-button size="small" style="margin-left: 10px">取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import mediaUpload from "../../../components/mediaUpload";
export default {
    name: "addClass",
    components: { mediaUpload },
    data() {
        return {
            statusTxt: '上架',
            payStatusTxt: '关闭',
            loading: false,
            inputVisible: false,
            inputValue: '',
            uploadPath: 'teacherCourse/' + this.$cookies.get('userId') + '/file',
            chapters: [],
            courseId: 0,
            pic: '',
            form: {
                name: '',
                teacherCourseId: '',
                path: '',
                size: 0,
                mimeType: '',
                // workload:'',
            },
            rules: {
                name: [
                    { required: true, message: '请输入课程名称', trigger: 'blur' },
                    { min: 2, max: 24, message: '长度在 2 到 24 个字符', trigger: 'blur' }
                ],
                videoId: [
                    { type: 'number', required: true, message: '请选择视频', trigger: 'change' }
                ],
                chapterId: [
                    { type: 'number', required: true, message: '请选择所属章节', trigger: 'change' }
                ],
                contents: [
                    { type: 'string', required: true, message: '请输入内容', trigger: 'blur' }
                ],
                path: [
                    { type: 'string', required: true, message: '请选择文件', trigger: 'change' }
                ],
                workload: [
                    { type: 'string', required: true, message: '请输入内容', trigger: 'blur' }
                ]
            },

        }
    },
    methods: {
        ...mapActions('teacherCourse', ['addTeacherCourseFile', 'getTeacherChapterList',"teacherFileAdd"]),
        uploadSuccess(data) {
            let name = data.path.split('/')
            let arr = name[name.length - 1]
            let index = arr.indexOf("_")
            if (index === -1 || index === 0) return this.$message.error('文件命名不正确，请重命名后重新选择上传：(章节名称_小节名称)')
            this.form.path = data.path
            this.form.mimeType = data.mimeType
            this.form.size = data.size
        },

        async onSubmit(formName) {

            if (!this.validateForm(formName)) {
                this.$message.error('请填写必填选项！')
                return false
            }

            let _this = this
            this.loading = true

            console.log(this.form)
            console.log('-----------------')

            this.teacherFileAdd(this.form).then(res => {
                console.log(res)
                if (res.ret === 0) {
                    this.$message.success('添加成功！')
                    this.loading = false

                    this.$router.push('/teacherCourse/detail/' + this.form.teacherCourseId + '/file')
                }
            }).finally(() => {
                this.loading = false
            })
        },
        validateForm(formName) {
            let validate = false
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    validate = true
                    return true
                } else {
                    validate = false
                    return false;
                }
            });
            return validate
        },
        handleClose(tag) {
            this.form.tags.splice(this.form.tags.indexOf(tag), 1);
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.form.tags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        onChange(contents) {
            console.log(contents)
            this.form.contents = contents
        },
    },
    mounted() {
        this.form.teacherCourseId = this.$route.params.id
        this.getTeacherChapterList({ teacherCourseId: this.form.teacherCourseId }).then(res => {
            console.log(res);
            if (res.ret === 0) {
                this.chapters = res.data
            }
        })
    }
}
</script>

<style>
.el-tag+.el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.videoImage {
    width: 200px;
    height: 120px;
}
</style>
